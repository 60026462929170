import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userIcon from "../../images/user-square.png";
import Calender from "../../images/calendar-2.png";
import Globe from "../../images/global-search.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Lock from "../../images/lock.png";
import Email from "../../images/sms.png";
import Phone from "../../images/call-calling.png";
import location from "../../images/location-1.png";
import CustomButton from "../components/commanButton";
import ArrowLeft from "../components/icon/arrow-left.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetSelf, GetSelfUpdate } from "../../api/auth";
import { Loader } from "../components/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ManageProfile() {
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    paymentPreference: [""],
    city: "",
    email: "",
  });

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("validation.firstName")),
    lastName: Yup.string().required(t("validation.lastName")),
    dob: Yup.string().required(t("validation.dob")),
    city: Yup.string().required(t("validation.city")),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.email")),
  });

  const onSubmit = async (values) => {
    try {
      console.log("\n\n\n<Information> Submit", values);
      setLoading(true);
      const formData = new FormData();
      // Append each form field to the formData
      formData.append("firstname", values.firstname);
      formData.append("lastname", values.lastname);
      formData.append("dob", values.dob);
      // Append each payment preference seperately
      values?.paymentPreference?.forEach((preference, index) => {
        formData.append(`paymentPreference[${index}]`, preference);
      });
      formData.append("city", values.city);
      formData.append("email", values.email);
      formData.append("role", "GuestUser");

      // Call the API to update user information
      const response = await GetSelfUpdate(formData);
      if (response?.remote === "success") {
      }
      console.log(response); // Handle the API response as needed

      // Navigate to the next step or perform other actions
    } catch (error) {
      console.error("Error updating user information:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  // FetchUser
  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      let response = await GetSelf();
      if (response.remote === "success") {
        let data = response?.data?.data?.doc;
        console.log(
          "\n\n<UploadAds> fetchUserData doc",
          response.data.data.doc
        );
        formik.setFieldValue("advertiser", data?._id || "");
        setUserData(data);
        setInitialValues({
          ...data,
          city: data?.address?.city,
          country: data?.address?.country,
        });
      } else {
      }
    } catch (error) {
      toast.error(`Error in getself:${error}`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    // Update formik values when userData changes
    if (userData && userData.paymentPreference) {
      // console.log(
      //   "\n\n<ManageProfile> userData.paymentPreference",
      //   userData?.paymentPreference?.filter((item) => item === "Paypal")[0]
      // );
      formik.setFieldValue("paymentPreference", userData.paymentPreference);
    }
  }, [userData]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   console.log("\n\n<ManageProfile> Formik", formik?.values);
  // });
  return (
    <Box
      sx={{ p: 3 }}
      dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            sx={{ mb: 3 }}
          >
            <IconButton
              sx={{
                background: "#fff",
                color: "#000",
                opacity: 1,
                "&:hover": { color: "#000", background: "#fff" },
              }}
              onClick={() => navigate("/user/profile")}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Box
              className="backArrow"
              sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
              component={"span"}
            >
              {t("profile")}
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("first_name")}
              </FormLabel>
              <TextField
                placeholder={t("enter_here")}
                fullWidth
                {...formik.getFieldProps("firstname")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={userIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.firstname && formik?.errors?.firstname && (
                <div style={{ color: "red" }}>{formik?.errors?.firstname}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("last_name")}
              </FormLabel>
              <TextField
                fullWidth
                {...formik.getFieldProps("lastname")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                  // input: {
                  //   "&::placeholder": {
                  //     color: "blue",
                  //   },
                  // },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={userIcon} />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("enter_here")}
              />
              {formik?.touched?.lastname && formik?.errors?.lastname && (
                <div style={{ color: "red" }}>{formik?.errors?.lastname}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("date_of_birth")}
              </FormLabel>
              <TextField
                type="date"
                fullWidth
                {...formik.getFieldProps("dob")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",

                    "& .MuiOutlinedInput-input": {
                      paddingTop: "14.5px",
                      paddingBottom: "14.5px",
                      textAlign: "end",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Calender} />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("enter_here")}
              />
              {formik?.touched?.dob && formik?.errors?.dob && (
                <div style={{ color: "red" }}>{formik?.errors?.dob}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {t("country")} <img src={Lock} alt="" />
              </FormLabel>
              <TextField
                className="countryDropdwon"
                fullWidth
                value={formik?.values?.country}
                disabled={true}
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "0 !important",
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",

                    "& .MuiOutlinedInput-input": {
                      paddingTop: "14.5px",
                      paddingBottom: "14.5px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#FBC515",
                    },

                    "& .MuiSelect-iconOutlined": { display: "none" },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Globe} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                      {/* <KeyboardArrowDownIcon sx={{ color: "#D5D5D5" }} /> */}
                    </InputAdornment>
                  ),
                }}
                defaultValue="1"
              >
                {/* <MenuItem value={"1"}>country 1</MenuItem>
                <MenuItem value={"2"}>country 2</MenuItem>
                <MenuItem value={"3"}>country 3</MenuItem> */}
              </TextField>
            </FormGroup>
            <Box>
              <FormLabel
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  mb: 1,
                }}
              >
                {t("payment_preference")}
              </FormLabel>
              <FormGroup
                sx={{
                  border: "1px solid #4F4F4F",
                  borderRadius: "8px",
                  background:
                    "linear-gradient(110deg, #151313 0%, #323131 100%)",
                  // px: 3,
                  // py: 1.5,
                  "& .MuiTypography-root": {
                    color: "#FBC515",
                    fontSize: "16px",
                  },
                }}
              >
                <FormControlLabel
                  sx={{ marginRight: "0 !important" }}
                  control={
                    <Checkbox
                      value={
                        formik?.values?.paymentPreference?.filter(
                          (item) => item === "Cash"
                        )[0]
                      }
                      checked={formik.values.paymentPreference.includes("Cash")}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Cash";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("cash")}
                />
                <FormControlLabel
                  sx={{ marginRight: "0 !important" }}
                  required
                  control={
                    <Checkbox
                      value={
                        formik?.values?.paymentPreference?.filter(
                          (item) => item === "Credit Card"
                        )[0]
                      }
                      checked={formik.values.paymentPreference.includes(
                        "Credit Card"
                      )}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Credit Card";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("credit_card")}
                />
                <FormControlLabel
                  sx={{ marginRight: "0 !important" }}
                  control={
                    <Checkbox
                      value={
                        formik?.values?.paymentPreference?.filter(
                          (item) => item === "Paypal"
                        )[0]
                      }
                      checked={formik.values.paymentPreference.includes(
                        "Paypal"
                      )}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Paypal";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("paypal")}
                />
                <FormControlLabel
                  sx={{ marginRight: "0 !important" }}
                  required
                  control={
                    <Checkbox
                      value={
                        formik?.values?.paymentPreference?.filter(
                          (item) => item === "Bank Transfer"
                        )[0]
                      }
                      checked={formik.values.paymentPreference.includes(
                        "Bank Transfer"
                      )}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Bank Transfer";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("bank")}
                />
              </FormGroup>
            </Box>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("email")}
              </FormLabel>
              <TextField
                placeholder={t("enter_here")}
                fullWidth
                {...formik.getFieldProps("email")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Email} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.email && formik?.errors?.email && (
                <div style={{ color: "red" }}>{formik?.errors?.email}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("address")}
              </FormLabel>
              <TextField
                fullWidth
                placeholder={t("address")}
                {...formik.getFieldProps("city")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={location} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.city && formik?.errors?.city && (
                <div style={{ color: "red" }}>{formik?.errors?.city}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {t("phone_number")} <img src={Lock} alt="" />
              </FormLabel>
              <TextField
              className="phoneNumbers"
                type="text"
                value={
                  formik?.values?.phone
                    ? "+" + formik?.values?.phone?.toString()
                    : ""
                }
                disabled={true}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#FBC515",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Phone} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
          </Stack>
          <Box sx={{ pt: 3 }}>
            <CustomButton
              btnText={t("update")}
              onClick={() => {
                if (Object.values(formik?.errors) > 0) {
                  console.warn(
                    "\n\n\n<Information> Formik Errors",
                    formik.errors
                  );
                } else {
                  console.log("\n\n\n<Information> Submitting ....");

                  formik.handleSubmit();
                }
              }}
              // icon={<img src={ArrowLeft} alt="" />}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
